import React, { useEffect, useRef, useState } from 'react';
import { Col, Text, TouchField, Row, modal } from 'components';
import { COLOR } from 'const';
import Store, { Api } from 'store';
import { Fontisto } from '@expo/vector-icons';
import { updateInProductionCheckData } from './ListOrders.Comps';
import { downloadArtworkAndPackingSlip, downloadPDFsAsZip } from 'helpers/FileHelper';
import ToastHelper from 'helpers/ToastHelper';
import RequestUtils from 'store/api-client/Request.utils';
import { getPDFDataFromPipelines } from './utils';

const DownloadPDFsModal = ({ pdfs, order }: { pdfs: any, order: any }) => {
  console.log('DownloadPDFsModal', pdfs);
  const { user } = Store.useUserStore();
  const [pdfWithPrinterIdentifierNames, setPdfWithPrinterIdentifierNames] = useState([]);
  const pdfNamesRef = useRef([]);
  useEffect(() => {
    pdfNamesRef.current = pdfWithPrinterIdentifierNames;
  }, [pdfWithPrinterIdentifierNames]);

  useEffect(() => {
    (async () => {
      const items = pdfs.map(item => {
        return {
          url: item.pdf,
          name: `${order["Order Number"]}_line-${item.lineId}.pdf`,
          lineId: item.lineId,
          orderId: item.orderId,
          orderNumber: order["Order Number"],
          shopifyProductId: item.product_id,
          quantity: item.quantity,
          designId: item.designId,
        }
      });
      const res = await Api.Design.getPDFNames({
        items,
      });
      setPdfWithPrinterIdentifierNames(res.data.data);
    })();
  }, []);

  console.log('pdfWithPrinterIdentifierNames', pdfWithPrinterIdentifierNames);

  const onPressDownloadAll = async () => {
    modal.hide();
    ToastHelper.showProgress(0);
    const pdfNames = await (() => {
      return new Promise((resolve) => {
        const intervalCheck = setInterval(() => {
          const pdfNames = pdfNamesRef.current;
          if (pdfNames?.length && pdfNames?.length === pdfs?.length) {
            clearInterval(intervalCheck);
            resolve(pdfNames)
          }
        }, 500);
      });
    })();

    const { displayValue } = getPDFDataFromPipelines(order.Pipelines);
    const slips = [{
      url: `${RequestUtils.host}/api/online-stores/order-packing-slips/${order['Order ID']}?clientId=${order['Client ID']}`,
      name: `${order["Order Number"]}.pdf`,
      pdfUrl: displayValue?.packingSlipUrl,
    }]

    const candles = (displayValue.candleStickers || []).map((item) => {
      const { lineId, candleSticker, orderId } = item;
      return {
        url: candleSticker,
        name: `${order["Order ID"]}_line-${lineId}.pdf`,
        lineId,
        orderId,
        shopifyProductId: item.product_id,
      }
    });

    downloadArtworkAndPackingSlip({
      // @ts-ignore
      artworks: pdfNames,
      slips,
      candles,
    }, (progress) => {
      ToastHelper.showProgress(progress);
    });
  }

  const handlePressPreview = (pdf) => {
    window.open(pdf, '_blank');
    if (user?.role === 'admin') {
      updateInProductionCheckData(order, { isDownloadedArtwork: true });
    }
  }

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} mb2 bold>Download PDFs</Text>
      {pdfs.map((pdf, idx) => {
        return (
          <Row flexGrow={1} justifyContent="space-between" key={idx}>
            <Text>{pdf.name}</Text>
            <TouchField ml2 cirle middle onPress={() => handlePressPreview(pdf.pdf)}>
              <Fontisto name="preview" size={16} color="black" />
            </TouchField>
          </Row>
        )
      })}
      <Row middle mt2>
        <TouchField
          height={30}
          width={70}
          middle
          borderColor={COLOR.FONT}
          borderRadius={15}
          borderWidth={1}
          m0
          ph2
          onPress={() => {
            return modal.hide();
          }}
        >
          <Text color={COLOR.FONT}>Cancel</Text>
        </TouchField>
        <TouchField
          height={30}
          ph2
          middle
          borderColor={COLOR.FONT}
          borderRadius={15}
          borderWidth={1}
          backgroundColor={COLOR.FONT}
          m0
          onPress={onPressDownloadAll}
        >
          <Text color={'white'}>Download all with printer identifier name</Text>
        </TouchField>
      </Row>
    </Col>
  );
};

export default DownloadPDFsModal;
