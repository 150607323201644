import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'antd';
import { Col, InputField, showPopupMessage, Text } from 'components';
import { COLORS, LOG_TYPES } from 'const';
import { TCMSOrder } from 'type';
import { requestMicroApi } from 'helpers/RequestHelper';
import { Api } from 'store';

interface Props {
  order: TCMSOrder;
  onUpdate: () => void;
}

const FIELDS = [
  {
    title: 'First Name',
    key: 'first_name',
  },
  {
    title: 'Last Name',
    key: 'last_name',
  },
  {
    title: 'Address line 1',
    key: 'address1',
  },
  {
    title: 'Address line 2',
    key: 'address2',
  },
  {
    title: 'Country',
    key: 'country',
  },
  {
    title: 'Town',
    key: 'town',
  },
  {
    title: 'City',
    key: 'city',
  },
  {
    title: 'Province',
    key: 'province',
  },
  {
    title: 'Post Code / Zip Code',
    key: 'zip',
  },
]

const UpdateAddressModal = (props: Props, ref) => {
  const { order, onUpdate } = props;
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const showError = (message: string) => {
    showPopupMessage({
      title: '',
      content: message,
      buttonOkText: 'OK',
      typeHighlight: 'danger',
      contentHighlight: 'Error'
    });
  }

  const onSaveCustomerAddress = async () => {
    const oldAddress = order["Raw Data"]?.shipping_address;
    const isUK = oldAddress?.country === 'United Kingdom';
    if (
      (isUK && data.country !== 'United Kingdom')
      || (!isUK && data.country === 'United Kingdom')
    ) {
      showError('You cannot change the country because it will affect the VAT costs');
      return;
    }
    setIsLoading(true);
    try {
      let formattedData = {
        ...data,
        name: `${data.first_name} ${data.last_name}`,
      }
      await requestMicroApi(`/api/bg/updateOrderData?id=${order.Id}`, {
        method: 'post',
        body: JSON.stringify({
          data: {
            ...order["Raw Data"],
            shipping_address: formattedData,
          }
        }),
      })
      await Api.Order.updateCustomerAddress({
        orderId: order["Order ID"],
        address: formattedData,
      })
      Api.Log.addLog({
        type: LOG_TYPES.UPDATE_ORDER,
        data: {
          orderId: order["Order ID"],
        },
        oldData: order["Raw Data"]?.shipping_address,
        newData: formattedData,
      })
      const pipelineId = order.Pipelines?.[order.Pipelines.length - 1]?.Id
      if (pipelineId) {
        await requestMicroApi(`/api/bg/pipeline/rerun-generate-packing-slip`, {
          method: 'post',
          body: JSON.stringify({
            pipelineId,
          }),
        })
      }
      setData(null);
      setShow(false);
      onUpdate();
    } catch (error) {
      alert(error?.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  }

  useImperativeHandle(ref, () => ({
    show: (data) => {
      setData(data);
      setShow(true);
    },
  }));

  return (
    <Modal
      open={show}
      title="Shipping address"
      closable
      onCancel={() => setShow(false)}
      width={640}
      footer={[
        <Button
          size="large"
          type="primary"
          key="save-candle"
          onClick={onSaveCustomerAddress}
          loading={isLoading}
          style={{ backgroundColor: COLORS.HEADLINES }}
        >
          <Text fontSize={16} fontWeight="600" colorWhite>Save</Text>
        </Button>
      ]}
    >
      <Col paddingTop={16}>
        {data && Object.keys(data)?.length > 0 && (
          <>
            {FIELDS.map(field => (
              <InputField
                key={field.key}
                title={field.title}
                value={data[field.key]}
                onChangeText={(v) => {
                  setData(s => ({
                    ...s,
                    [field.key]: v,
                  }))
                }}
                marginBottom={12}
              />
            ))}
          </>
        )}
      </Col>
    </Modal>
  );
};

export default forwardRef(UpdateAddressModal);
