import React, { useEffect, useMemo, useState } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, Input02, Select01, useUIState, Grid, CalendlyButton, TouchField, RatioCol, showPopupMessage } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import Store, { Api, Request } from 'store';
import ShopifyCodeSnippet from './UpsertStore.ShopifyCodeSnippet';
import { Image } from 'react-native';
import { ASSETS } from 'assets';
import ShopifyAdminStoreUrl from './UpsertStore.ShopifyAdminStoreUrl';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import apiClient from 'store/api-client';

const STORE_TYPES = [
  { label: 'Shopify', value: 'shopify' },
  // { label: 'WooCommerce', value: 'wordpress' },
  { label: 'Etsy', value: 'etsy' },
]

const UpsertStore: IScreen = () => {

  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id, storetype } = route.params || {};

  const ShopStore = Store.useShopStore();
  const UserStore = Store.useUserStore();

  const { shop, setShop } = ShopStore.useShop(id);
  const [isShopConnected, setIsShopConnected] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [isLoadingEtsy, setIsLoadingEtsy] = useState(false);
  const isDev = window.location.hostname.includes('dev.') || window.location.hostname.includes('localhost');
  const [connectType, setConnectType] = useState<'app' | 'manual'>(isDev ? 'manual' : 'app');

  const onChangeField = (label) => (newValue) => {
    setShop({
      ...shop,
      [label]: newValue,
    })
    setIsChanged(true);
  }

  useEffect(() => {
    return () => {
      setIsChanged(false);
    }
  }, [])

  useEffect(() => {
    if (storetype === 'shopify' && id === 'new') {
      setShop({
        ...shop,
        type: 'shopify',
      });
    }
    if (storetype === 'etsy' && id === 'new') {
      setShop({
        ...shop,
        type: 'etsy',
      });
    }
  }, [storetype]);

  useEffect(() => {
    if (id === 'new') return;
    if (!shop || !shop.url || !shop.data?.shopifyAccessToken) return;
    if (shop.resellerId !== UserStore.getResellerId(UserStore.user)) return;
    ShopStore.resellerHealthCheck();
    Api.Shop.getShopifyStoreInfo({
      cms_store_id: shop.id,
    })
      .then(res => {
        if (res.data.success && res.data.data?.id) {
          console.log('Shop Info', res.data.data);
          setIsShopConnected(true);
        }
      })
  }, [shop, id]);

  const submit = async () => {
    setSubmitUI({ loading: true });
    try {
      const cleanedUrl = shop?.url?.endsWith('/') ? shop?.url.slice(0, shop?.url.length - 1) : shop?.url
      const res = await Store.Api.Shop.upsert({
        id: id === 'new' ? undefined : id,
        name: shop?.name,
        data: shop?.data,
        url: cleanedUrl,
        type: shop?.type,
      });
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
        // alert(res.data.error);
        return;
      }
      ShopStore.resellerHealthCheck();
      ShopStore.updateShop(id, res.data.data);
      navigation.reset({
        index: 0,
        routes: [{ name: SCREEN.UpsertStore, params: { id: res.data.data.id } }],
      });
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(String(err));
    }
    setSubmitUI({ loading: false });
  }

  const installApp = async () => {
    // setSubmitUI({ loading: true });
    // try {
    //   const cleanedUrl = shop?.url?.endsWith('/') ? shop?.url.slice(0, shop?.url.length - 1) : shop?.url
    //   const slug = cleanedUrl.replace('.myshopify.com', '').replace('https://', '');
    //   const genNameFromSiteUrl = () => {
    //     return slug.split('-').map(v => `${v[0].toUpperCase()}${v.slice(1, v.length)}`).join(' ');
    //   }
    //   const name = shop.name || genNameFromSiteUrl();
    //   const res = await Store.Api.Shop.upsert({
    //     id: id === 'new' ? undefined : id,
    //     name: name,
    //     data: shop?.data,
    //     url: cleanedUrl,
    //     type: shop?.type,
    //   });
    //   if (res.data.success) {
    //     window.location.href = `${Request.host}/api/online-stores/shopify-app-connect?shop=${slug}`;
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
    // setSubmitUI({ loading: false });
    window.location.href = `https://apps.shopify.com/bottled-goose-print-on-demand`;
  }

  const installAppEtsy = async () => {
    if (!shop?.name) {
      showPopupMessage({
        title: '',
        content: 'Please enter store name first',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
      return;
    }
    if (isLoadingEtsy) return;
    setIsLoadingEtsy(true);
    try {
      const res = await apiClient.Api.Shop.etsyRequestConnect();
      const { codeChallenge, codeVerifier, clientId } = res.data.data;
      const redirectUrl = `${window.location.origin}/etsy-connect-callback`;
      const scopes = `listings_r listings_d listings_w shops_r transactions_r transactions_w`;
      localStorage.setItem('BG_EtsyShopName', shop.name);
      localStorage.setItem('BG_EtsyCodeVerifier', codeVerifier);
      window.open(`https://www.etsy.com/oauth/connect?response_type=code&client_id=${clientId}&code_challenge=${codeChallenge}&code_challenge_method=S256&redirect_uri=${redirectUrl}&scope=${scopes}&state=superstate`, "_blank");
    } catch (error) {
      showPopupMessage({
        title: '',
        content: error.message || JSON.stringify(error),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    } finally {
      setIsLoadingEtsy(false);
    }
  }

  const createDummyShopifyProduct = async () => {

    try {
      const res = await Store.Api.Shop.testShopifyConnection({
        url: shop?.url,
        token: shop?.data?.shopifyAccessToken,
        isNewStore: id === 'new',
        currentStoreId: id,
      });
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
        // alert(res.data.error)
      } else if (res.data.data.id) {
        showPopupMessage({
          title: '',
          content: `Successfully create a dummy product: ${res.data.data.id}, please check it in the Shopify admin.`,
          buttonOkText: 'OK',
          // 
          typeHighlight: 'success',
          contentHighlight: 'Success'

        });
        // alert(`Successfully create a dummy product: ${res.data.data.id}, please check it in the Shopify admin`);
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: 'ERROR: ' + String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert('ERROR: ' + String(err))
    }

  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3>{id === 'new' ? 'New Store Connection' : `${shop?.name}`}</Text>
        <Row>
          <CalendlyButton />
          {id && id !== 'new' ? (
            <Button
              ml1
              isLoading={submitting}
              text={"Save"}
              width={100} height={40} borderRadius={20}
              onPress={submit}
              disabled={!isChanged}
              opacity={!isChanged ? 0.5 : 1}
            />
          ) : (connectType === 'manual' && shop?.type !== 'etsy' && (
            <Button
              ml1
              isLoading={submitting}
              text={"Connect"}
              width={100} height={40} borderRadius={20}
              onPress={submit}
            />
          ))}
        </Row>
      </Row>
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={'scroll'}>
        <Col flex1>
          <Grid xs='100%' md='50%' alignItems={'flex-start'} mb2>
            <Col m1>
              {(id !== 'new' && !!shop && shop.resellerId !== UserStore.getResellerId(UserStore.user)) ? (
                <Row mb2 p1>
                  <AntDesign name="warning" size={24} color="#FFA500" />
                  <Text ml0 color="#FFA500">This store is already connected with other account. please try other stores.</Text>
                </Row>
              ) : (
                isShopConnected && (
                  <Row mb2 p1>
                    <AntDesign name="checkcircle" size={24} color="#3BB178" />
                    <Text ml0 color="#3BB178">Your store is connected</Text>
                  </Row>
                )
              )}
              <Text subtitle1 mb1>Store Name</Text>
              <Input02
                height={35}
                value={shop?.name || ''}
                onChange={onChangeField('name')}
                mb2
              />
              <Text subtitle1 mb1>Store type:</Text>
              <Select01
                value={STORE_TYPES.find(i => i.value === shop?.type)}
                onChange={(newVal) => {
                  setShop({
                    ...shop,
                    type: newVal.value,
                  })
                }}
                options={STORE_TYPES}
                innerProps={{
                  isDisabled: id && id !== 'new',
                }}
                mb2
              />
              {shop?.type === 'shopify' && connectType === 'app' && (
                <>
                  {shop?.url && shop?.data?.shopifyAccessToken ? (
                    <>
                      {console.log('shop?.data', shop?.data)}
                      <Button
                        text='Test connection (create dummy product)'
                        outline
                        borderRadius={15}
                        height={30}
                        width={320}
                        mb2
                        onPress={createDummyShopifyProduct}
                      />
                    </>
                  ) : (
                    <Col>
                      <Text mb0>Please install app Bottled Goose: Print on Demand. It will redirect back to here after the installation is done</Text>
                      <Button
                        text='Install App'
                        borderRadius={20}
                        height={40}
                        width={320}
                        mv2
                        onPress={installApp}
                      />
                    </Col>
                  )}
                </>
              )}

              {shop?.type === 'shopify' && connectType === 'manual' && (
                <>
                  <Col>
                    <Text subtitle1 mb1>Store URL</Text>
                    {shop?.type === 'shopify' && (
                      <Text caption mb1>* It should by {`https://[your-store-slug].myshopify.com`}</Text>
                    )}
                    <Input02
                      height={35}
                      value={shop?.url || ''}
                      onChange={onChangeField('url')}
                      mb2
                    />
                    <Text subtitle1 mb1>Shopify Access Token</Text>
                    <Text caption mb1>* You can get Access Token by creating new custom app in Shopify Admin</Text>
                    <Text caption mb1>* Please check the manual for more information</Text>
                    <Input02
                      height={35}
                      value={shop?.data?.shopifyAccessToken || ''}
                      onChange={newVal => {
                        setShop({
                          ...shop,
                          data: {
                            ...shop.data,
                            shopifyAccessToken: newVal,
                          }
                        })
                      }}
                      mb2
                    />
                  </Col>
                  {shop?.url && shop?.data?.shopifyAccessToken && (
                    <Button
                      text='Test connection (create dummy product)'
                      outline
                      borderRadius={15}
                      height={30}
                      width={320}
                      mb2
                      onPress={createDummyShopifyProduct}
                    />
                  )}
                </>
              )}

              {shop?.type === 'wordpress' && (
                <Col>
                  <Text subtitle1 mb1>Wordpress integration coming soon</Text>
                </Col>
              )}

              {shop?.type === 'etsy' && !shop?.id && (
                <Col>
                  <Text mb0>Please install app "Bottled Goose: Print on Demand". It will redirect back to here after the installation is done</Text>
                  <Button
                    text='Install App'
                    borderRadius={20}
                    height={40}
                    width={320}
                    mv2
                    isLoading={isLoadingEtsy}
                    onPress={installAppEtsy}
                  />
                  <Text mb0>The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</Text>
                </Col>
              )}
            </Col>
            <Col m1>
              {/* MANUAL CONNECTION */}
              {shop?.type === 'shopify' && connectType === 'manual' ? (
                <Col>
                  <Text subtitle1 mb1>Tickboxes needed for access token</Text>
                  <Text caption mb1>* {`Settings -> Apps and sales channels -> Develop custom apps -> {app name} -> Configuration -> Admin API access scopes`}</Text>
                  <Col backgroundColor={COLOR.GREY_LIGHT} round1 p2 mb2>
                    {
                      "write_products, read_products, write_publications, read_publications, write_orders, read_orders, write_discounts, read_discounts, write_fulfillments, read_fulfillments, write_custom_fulfillment_services, read_custom_fulfillment_services, read_inventory, write_inventory, write_third_party_fulfillment_orders, read_third_party_fulfillment_orders, write_merchant_managed_fulfillment_orders, read_merchant_managed_fulfillment_orders, write_assigned_fulfillment_orders, read_assigned_fulfillment_orders"
                        .split(',').map((v, vI) => {
                          return (
                            <Text color='#800000' key={'permission-' + vI}>{v.trim()}</Text>
                          )
                        })
                    }
                    {/* <Text color='#800000'>write_products</Text>
                    <Text color='#800000'>read_products</Text>
                    <Text color='#800000'>write_publications</Text>
                    <Text color='#800000'>read_publications</Text>
                    <Text color='#800000'>write_orders</Text>
                    <Text color='#800000'>read_orders</Text>
                    <Text color='#800000'>write_discounts</Text>
                    <Text color='#800000'>read_discounts</Text>
                    <Text color='#800000'>write_fulfillments</Text>
                    <Text color='#800000'>read_fulfillments</Text>
                    <Text color='#800000'>write_custom_fulfillment_services</Text>
                    <Text color='#800000'>read_custom_fulfillment_services</Text> */}
                  </Col>

                  <ShopifyCodeSnippet />
                </Col>
              ) : null}

              {/* STORE CONNECTED INSTUCTIONS */}
              {shop?.data?.shopifyAccessToken && (
                <>
                  {connectType === 'app' && (
                    <Text subtitle1 mb1>Store status: Connected</Text>
                  )}
                  {/* <Text mb2>Next step: make sure to include these blocks to your store theme</Text>

                  <Text mb0>Add Personalised Button clock to Product detail page using Customize</Text>
                  <RatioCol width={'100%'} ratio={1976 / 950} mb1>
                    <Image source={ASSETS.CUSTOMIZE_ADD_BLOCKS_1} style={{ width: '100%', height: '100%' }} />
                  </RatioCol>
                  <RatioCol width={'100%'} ratio={1382 / 950} mb1>
                    <Image source={ASSETS.CUSTOMIZE_ADD_BLOCKS_2} style={{ width: '100%', height: '100%' }} />
                  </RatioCol>


                  <Text mt1>and Publish some product</Text> */}
                </>
              )}
            </Col>
          </Grid>
        </Col>
        {/* {shop?.type === 'shopify' &&
          <Text h4 m1>Shopify App coming soon 'Easy self connection'</Text>
        } */}
      </Col>
      {shop?.type === 'shopify' &&
        <Col position={'fixed'} bottom={20} right={30}>
          <Image
            source={ASSETS.SHOPIFY_TEXT}
            style={{ height: 60, aspectRatio: 40 / 12 }}
            resizeMode='contain'
          />
        </Col>
      }
      {shop?.type === 'etsy' &&
        <Col position={'fixed'} bottom={20} right={30}>
          <Image
            source={ASSETS.ETSY_LOGO}
            style={{ height: 60, aspectRatio: 200 / 95 }}
            resizeMode='contain'
          />
        </Col>
      }
    </CMSLayout>
  );
};

UpsertStore.routeInfo = {
  title: 'My Store - Bottled Goose',
  path: '/my-stores/:id'
};

export default UpsertStore;
