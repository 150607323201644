import { Col, Row, Text, TouchField, Button, modal, showPopupMessage, InputField, SelectField } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { TCMSOrder } from 'type';
import Store, { Api } from 'store';
import { COLOR, LOG_TYPES } from 'const';
import { useWindowDimensions } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import { ORDER_STAGE, ORDER_STATUS } from 'store/Order.Store';
import { omitBy, isNil } from 'lodash';
import { commonFuncs } from '../ListOrders.funcs';

export const AdminOverrideOrderInfo = ({ order, onSuccess }: { order: TCMSOrder, onSuccess?: () => void }) => {
  const OrderStore = Store.useOrderStore();
  const [trackingNumber, setTrackingNumber] = useState('');
  const [rmOrderIdentifier, setRmOrderIdentifier] = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const { width, height } = useWindowDimensions();
  const [status, setStatus] = useState('');
  const [stage, setStage] = useState('');
  const [dispatchedDate, setDispatchedDate] = useState<string>('');

  const [isSaving, setIsSaving] = useState(false);

  const deleteOrder = async (val) => {
    const success = await commonFuncs.onDelete([val]);
    if (!success) return;
    onSuccess?.();
  }

  const statusOptions = ORDER_STATUS.map(v => ({ label: v, value: v }));
  const stageOptions = ORDER_STAGE.map(v => ({ label: v, value: v }));

  const shippingMethodsOptions = [
    'Royal Mail 48',
    'Royal Mail 24',
    'DPD next day',
    'DHL International',
    'Royal Mail International',
  ].map(v => ({ label: v, value: v }))

  const shopifyDeleted = useMemo(() => {
    const last = order.Pipelines?.[order.Pipelines?.length - 1];
    if (!last) return false;
    return last.SharedData?.shopifyDeleted;
  }, [order])

  useEffect(() => {
    const last = order.Pipelines[order.Pipelines.length - 1];
    if (last && last.SharedData && last.SharedData.shippingMethod) {
      setShippingMethod(last.SharedData.shippingMethod);
    }
    if (last && last.SharedData && last.SharedData.dispatchedDate) {
      setDispatchedDate(last.SharedData.dispatchedDate);
    }
    // if (last && last.SharedData && last.SharedData.trackingNumber) {
    //   setTrackingNumber(last.SharedData.trackingNumber);
    //   return;
    // }
    if (!!last?.SharedData?.trackingNumber) {
      setTrackingNumber(last.SharedData.trackingNumber);
    }
    if (!!last?.SharedData?.royalMailOrderIdentifier) {
      setRmOrderIdentifier(last.SharedData.royalMailOrderIdentifier);
    }
    if (order.StageStatus) setStatus(order.StageStatus);
    if (order.Stage) setStage(order.Stage);
  }, [order]);

  const save = async () => {
    setIsSaving(true);
    try {
      if (status || stage) {
        await OrderStore.updateOrderStage([order], omitBy({
          Stage: stage,
          StageStatus: status,
        }, isNil));
      }
      const obj = {
        shippingMethod: shippingMethod || undefined,
        trackingNumber: trackingNumber || undefined,
        dispatchedDate: dispatchedDate || undefined,
        royalMailOrderIdentifier: rmOrderIdentifier || undefined,
      };
      const atLeastOneKeyNotUndefiend = Object.keys(obj).some(key => obj[key] !== undefined);
      if (atLeastOneKeyNotUndefiend) await OrderStore.updateShareData(order, obj);
      const last = order.Pipelines?.[order.Pipelines.length - 1];
      if (trackingNumber && trackingNumber !== last?.SharedData?.trackingNumber
        && order['Store ID'] && last?.SharedData?.canBeProcessedItems?.length
        && !order['Raw Data']?.is_sample_request
      ) {
        await Api.Shop.updateShopifyOrderStatus({
          orders: [{
            lineItems: last?.SharedData?.canBeProcessedItems,
            orderId: +order['Order Source ID'] || +order['Order ID'],
            storeId: order['Store ID'],
            trackingNumber: trackingNumber,
          }]
        })
      }
      Api.Log.addLog({
        type: LOG_TYPES.OVERRIDE_ORDER,
        data: {
          orderId: order['Order ID'],
        },
        oldData: {
          stage: order.Stage,
          stageStatus: order.StageStatus,
          shippingMethod: last?.SharedData?.shippingMethod,
          trackingNumber: last?.SharedData?.trackingNumber,
          dispatchedDate: last?.SharedData?.dispatchedDate,
          royalMailOrderIdentifier: last?.SharedData?.dispatchedDate,
        },
        newData: {
          stage,
          stageStatus: status,
          shippingMethod,
          trackingNumber,
          dispatchedDate,
          royalMailOrderIdentifier: rmOrderIdentifier,
        }
      })
      onSuccess?.();
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(String(err));
    }
    setIsSaving(false);
  }

  return (
    <Col round1 bgWhite shadow p2 onPress={() => { }} activeOpacity={1} width={width <= 600 ? width * 0.95 : width * 0.5} minHeight={width <= 600 ? height * 0.95 : 300}>
      <Text h3 mb2>Order override</Text>
      <Col flex1>
        <InputField
          title="Royal Mail Order Identifier"
          value={rmOrderIdentifier}
          onChangeText={setRmOrderIdentifier}
          marginBottom={12}
        />
        <InputField
          title="Tracking Number"
          value={trackingNumber}
          onChangeText={setTrackingNumber}
          marginBottom={12}
        />
        <SelectField
          title="Shipping Method"
          options={shippingMethodsOptions}
          value={shippingMethod}
          onChange={(val: string) => setShippingMethod(val)}
          marginBottom={12}
        />
        <InputField
          title="Dispatched Date"
          value={dispatchedDate}
          onChangeText={setDispatchedDate}
          marginBottom={12}
        />
        <SelectField
          title="Order Stage"
          options={stageOptions}
          value={stage}
          onChange={(val: string) => setStage(val)}
          marginBottom={12}
        />
        <SelectField
          title="Order Status"
          options={statusOptions}
          value={status}
          onChange={(val: string) => setStatus(val)}
          marginBottom={12}
        />
      </Col>
      {shopifyDeleted && <Text color="red" mb2>This order has been deleted on Shopify</Text>}
      <Row justifyContent={'space-between'}>
        <Col>
          <TouchField flexDirection={'row'} width={140} height={30} borderRadius={15} middle onPress={() => deleteOrder(order)}>
            <EvilIcons name="trash" size={24} color={COLOR.FONT} />
            <Text ml0>Delete order</Text>
          </TouchField>
        </Col>
        <Row>
          <Button
            isLoading={isSaving}
            text="Save"
            width={100}
            height={30}
            mr1
            onPress={save}
          />
          <Button
            outline
            text="Close"
            width={100}
            height={30}
            onPress={() => modal.hide()}
          />
        </Row>
      </Row>
    </Col>
  );
}
